import React from 'react'
import { Dispatch } from 'redux'
import { Paragraph1, H6, H2 } from '@styles/Global.styles';
import { Grid } from 'semantic-ui-react';
import { OrderConfirmationContentModel } from '@models/OrderConfirmation/OrderConfirmationContentModel';
import { OrderConfirmationDataModel } from '@models/OrderConfirmation/OrderConfirmationDataModel';
import { OrderConfirmationLink, OrderConfirmationLinkContainer, OrderConfirmationContactInfoContainer, OrderConfirmationColumn, LoadingIcon, GoogleReviewButton, OrderConfirmationContactInfoContainerMobile } from './OrderConfirmation.styles';
import { OrderConfirmationService } from '@services/orderConfirmationService';
import OrderConfirmationDeliveryProgress from './OrderConfirmationDeliveryProgress';
import OrderConfirmationContent from './OrderConfirmationContent';
import moment from 'moment';
import { userFacebookPixelSent, setUserFacebookPixelSent, userFloodlightSent, setUserFloodlightSent } from '@redux/localStorage/user';
import { connect } from 'react-redux';
import { currentBasketSelector } from '@redux/selectors/basketSelector';
import { clearBasket, clearBasketAndCheckout,  updateDeliveryAddress, updateIsAcceptStatement, updatePersonalDetails } from "@redux/actions/checkout";
import { Record } from 'immutable';
import { currentCheckoutSelector } from '@redux/selectors/checkoutSelector';
import InfoModal from '@components/InfoModal/InfoModal';
import theme from '@styles/theme';
import { Link, graphql } from 'gatsby';
import { encodeURIParameterIfNotEncoded } from '@utils/Helpers';
import { ButtonTypes, PaymentOptions } from '@utils/Constant';
import { getCurrentCheckout, getEmptyCheckout } from '@redux/localStorage/checkout';
import { Address, Checkout } from '@models/Checkout/Checkout';
const queryString = require('query-string');
const reactPixel = require('react-facebook-pixel');
import { KpButton } from "@elements/index"

interface OrderConfirmationProps {
    orderConfirmationContent: OrderConfirmationContentModel
    location: any
    clearBasket: Function,
    clearBasketAndCheckout: Function,
    siteMetadata: {
        apiUrl: string
        environment: string
        lastBuilt: string
    },
    transactionId?: string,
    updateDeliveryAddress: Function,
    updatePersonalDetails: Function,
    updateIsAcceptStatement: Function
}
interface OrderConfirmationState {
    isLoading: boolean
    success: boolean
    error: string
    orderConfirmationData?: OrderConfirmationDataModel
    gapiReady: boolean
    showReviewModal: boolean
    transactionId: string
}

class OrderConfirmationContainer extends React.Component<OrderConfirmationProps, OrderConfirmationState>{

    constructor(props: OrderConfirmationProps) {
        super(props);
        this.state = {
            isLoading: true,
            success: true,
            error: '',
            orderConfirmationData: undefined,
            gapiReady: false,
            showReviewModal: false,
            transactionId: ''
        }
    }

    sendFacebookPixelPurchase = (transactionId: string, orderConfirmationData: OrderConfirmationDataModel) => {
        if (this.props.siteMetadata.environment === 'production') {
            reactPixel.default.init('313542812355980');
            let totalOrderPrice = 0;
            let contents: { id: string; quantity: number; }[] = [];
            orderConfirmationData.Items.forEach((item) => {
                totalOrderPrice += (item.PriceExGst + item.PriceGst);
                contents.push({ 'id': item.PlateRangeName, "quantity": 1 });
            });
            reactPixel.default.track('Purchase', {
                content_type: 'product',
                contents: contents,
                value: totalOrderPrice,
                currency: 'NZD'
            });
            setUserFacebookPixelSent(transactionId);
        }
    }


    closeReviewModal = (transactionId: string) => {
        if (transactionId !== '') {
            this.setState({
                showReviewModal: false
            })

        }
    }

    componentDidMount() {
        const query = queryString.parse(window.location.search);
        const transactionId = query.rcpt;
        const success = query.success;
        const id = query.key.replaceAll(' ', '+');
        const error = query.msg;
        const checkout = getCurrentCheckout()

        if (success == 1) {
            this.setState({ success: true });
            if (transactionId && id) {
                OrderConfirmationService.getOrderConfirmationData(this.props.siteMetadata.apiUrl, encodeURIParameterIfNotEncoded(id)).then((data: any) => {
                    if (data.data.Data !== null) {
                        const orderConfirmationData = data.data.Data as OrderConfirmationDataModel;

                        this.setState({
                            orderConfirmationData: orderConfirmationData,
                            isLoading: false,
                            success: true,
                            transactionId: transactionId
                        })
                    }
                    else {
                        this.setState({
                            isLoading: false
                        })
                    }
                });
            }
            else {
                this.setState({
                    isLoading: false
                })
            }
        }
        else {
            this.setState({
                isLoading: false,
                success: false,
                error: error
            })
        }
        if (checkout.isDealerOrder && success == 1){
            this.props.clearBasket();
            const emptyCheckout = getEmptyCheckout()
            let checkout = getCurrentCheckout()
            checkout.ownerDetails = emptyCheckout.ownerDetails
            checkout.vehicleDetails = emptyCheckout.vehicleDetails
            checkout.companyDetails = emptyCheckout.companyDetails
            checkout.ownerAddress = emptyCheckout.ownerAddress
            this.props.updateDeliveryAddress(emptyCheckout.deliveryAddress)
            this.props.updatePersonalDetails(checkout)
            this.props.updateIsAcceptStatement(emptyCheckout.isAcceptStatement)
        } else {
            this.props.clearBasketAndCheckout()
        }       
    }

    loadSurveyApi = (transactionId: string, email: string) => {
        const script = document.createElement("script");
        script.src = "https://apis.google.com/js/platform.js?onload=renderOptIn";
        script.async = true;
        script.onload = () => {
            window.gapi.load('surveyoptin', () => {
                window.gapi.surveyoptin.render(
                    {
                        "merchant_id": 134240656,
                        "order_id": transactionId,
                        "email": email,
                        "delivery_country": "nz",
                        "estimated_delivery_date": moment().add(7, "day").format("YYYY-MM-DD")
                    });
                this.setState({ gapiReady: true });
            })
        }
        document.body.appendChild(script);
    }
    render() {

        const { orderConfirmationData, showReviewModal, transactionId } = this.state;
        const { orderConfirmationContent } = this.props;
        const isMultiOrder = orderConfirmationData === undefined ? undefined : orderConfirmationData.Items.length > 1;
        const checkout = getCurrentCheckout()
        const isDealerOrder = checkout.isDealerOrder
        return (
            <div key='this.props.location.pathname'>
                {
                    !this.state.isLoading && this.state.success &&
                    <div>
                        {
                            orderConfirmationData !== undefined &&
                            <Grid>
                                <InfoModal showCloseButton={true} content='We want to hear from you!' title='Leave a Review' modalOpen={showReviewModal} onClose={() => this.setState({ showReviewModal: false })}>
                                    <Link target='__blank' to='https://www.google.com/search?sxsrf=ALeKk00I8UAZIlM_FlJezX4E2ZaDP9UD-g%3A1597875656882&ei=yKU9X-O8NdGV4-EPn6-_6As&q=kiwiplates&oq=kiwiplates&gs_lcp=CgZwc3ktYWIQAzINCC4QxwEQrwEQJxCTAjICCAAyAggAMgIIADICCAAyAggAMgIIADICCAAyBwgAEBQQhwIyAggAOgQIABBHOggILhDHARCvAToKCC4QxwEQrwEQCjoICC4QxwEQowI6BAgAEApQ6i1YkjJgijNoAHABeACAAcYBiAH4BpIBAzAuNZgBAKABAaoBB2d3cy13aXrAAQE&sclient=psy-ab&ved=0ahUKEwjjwLHXpqjrAhXRyjgGHZ_XD70Q4dUDCAw&uact=5#lrd=0x6d0d48057eb92787:0xcb1617cfb7860625,3,,,'>
                                        <KpButton onClick={() => this.setState({ showReviewModal: false })} id='paynow' buttonType='primary' color={theme.brand.colors.blue}>
                                            Leave a Review
                                        </KpButton>
                                    </Link>
                                </InfoModal>
                                {
                                    this.props.siteMetadata.environment === 'production' && orderConfirmationData.Items.length > 0 && !userFloodlightSent(orderConfirmationData.Items[0].TransactionId.toString()) &&
                                    orderConfirmationData.Items.map((item) => {
                                        const revenue = item.PriceExGst + item.PriceGst;
                                        setUserFloodlightSent(item.TransactionId.toString());
                                        return <iframe key={item.TransactionId} title={item.TransactionId.toString()} src={`https://6974303.fls.doubleclick.net/activityi;src=6974303;type=checkout;cat=check0;qty=1;cost=${revenue};u1=${item.PlateDesignId}/${item.DualPlateDesignId !== null ? item.DualPlateDesignId : item.PlateDesignId};u2=${revenue};u3=${item.PlateDesignName}/${item.DualPlateDesignName};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=${item.TransactionId}`} width="1" height="1" style={{ display: 'none' }}></iframe>
                                    })
                                }
                                {
                                    isMultiOrder ?
                                        <Grid.Row>
                                            <OrderConfirmationContent heading={orderConfirmationContent.multipleOrder.heading} body={orderConfirmationContent.multipleOrder.body}></OrderConfirmationContent>
                                        </Grid.Row> :
                                        // DEALER ON REQUEST 
                                        isDealerOrder && checkout.paymentType == PaymentOptions.DealerPaymentRequest ?
                                            <Grid.Row>
                                                <OrderConfirmationContent heading={orderConfirmationContent.dealerTopReviewStatement.heading} body={orderConfirmationContent.dealerTopReviewStatement.body}></OrderConfirmationContent>
                                                <OrderConfirmationContent heading="" body={orderConfirmationContent.dealerOnRequest.body}></OrderConfirmationContent>
                                            </Grid.Row>
                                            :
                                            // DEALER MANUFACTURE NOW
                                            isDealerOrder && checkout.manufactureNow ?
                                            <Grid.Row>
                                                <OrderConfirmationContent heading={orderConfirmationContent.dealerTopReviewStatement.heading} body={orderConfirmationContent.dealerTopReviewStatement.body}></OrderConfirmationContent>
                                                <OrderConfirmationContent heading="" body={orderConfirmationContent.dealerManufactureNow.body}></OrderConfirmationContent>
                                            </Grid.Row>
                                            :
                                            // DEALER NANUFACTURE LATER
                                            isDealerOrder && !checkout.manufactureNow ? 
                                            <Grid.Row>
                                                <OrderConfirmationContent heading={orderConfirmationContent.dealerTopReviewStatement.heading} body={orderConfirmationContent.dealerTopReviewStatement.body}></OrderConfirmationContent>
                                                <OrderConfirmationContent heading="" body={orderConfirmationContent.dealerManufactureLater.body}></OrderConfirmationContent>
                                            </Grid.Row>
                                            :
                                        // CREATE NEW PAY IN FULL MANUFACTURE NOW
                                        orderConfirmationData.TransactionType === 1 && orderConfirmationData.DeliveryType !== 1 && orderConfirmationData.LaybyDetails === null && !orderConfirmationData.IsGift ?
                                            <Grid.Row>
                                                <OrderConfirmationContent heading={orderConfirmationContent.createNewFullPaymentManufactureNow.heading} body={orderConfirmationContent.createNewFullPaymentManufactureNow.body}></OrderConfirmationContent>
                                            </Grid.Row> :

                                            // CREATE NEW PAY IN FULL MANUFACTURE LATER
                                            orderConfirmationData.TransactionType === 1 && orderConfirmationData.DeliveryType === 1 && orderConfirmationData.LaybyDetails === null && !orderConfirmationData.IsGift ?
                                                <Grid.Row>
                                                    <OrderConfirmationContent heading={orderConfirmationContent.createNewFullPaymentManufactureLater.heading} body={orderConfirmationContent.createNewFullPaymentManufactureLater.body}></OrderConfirmationContent>
                                                </Grid.Row> :

                                                // REDESIGN PAY IN FULL MANUFACTURE LATER
                                                (orderConfirmationData.TransactionType === 2 || orderConfirmationData.TransactionType === 3) && orderConfirmationData.DeliveryType === 1 && orderConfirmationData.LaybyDetails === null && !orderConfirmationData.IsGift ?
                                                    <Grid.Row>
                                                        <OrderConfirmationContent heading={orderConfirmationContent.redesignFullPaymentManufactureLater.heading} body={orderConfirmationContent.redesignFullPaymentManufactureLater.body}></OrderConfirmationContent>
                                                    </Grid.Row> :

                                                    // REDESIGN PAY IN FULL MANUFACTURE NOW
                                                    (orderConfirmationData.TransactionType === 2 || orderConfirmationData.TransactionType === 3) && orderConfirmationData.DeliveryType !== 1 && orderConfirmationData.LaybyDetails === null && !orderConfirmationData.IsGift ?
                                                        <Grid.Row>
                                                            <OrderConfirmationContent heading={orderConfirmationContent.redesignFullPaymentManufactureNow.heading} body={orderConfirmationContent.redesignFullPaymentManufactureNow.body}></OrderConfirmationContent>
                                                        </Grid.Row> :

                                                        // CREATE NEW LAYBY
                                                        orderConfirmationData.TransactionType === 1 && orderConfirmationData.LaybyDetails !== null && !orderConfirmationData.IsGift ?
                                                            <Grid.Row>
                                                                <OrderConfirmationContent heading={orderConfirmationContent.createNewLaybyPayment.heading} body={orderConfirmationContent.createNewLaybyPayment.body}></OrderConfirmationContent>
                                                            </Grid.Row> :

                                                            // GIFTING
                                                            orderConfirmationData.IsGift ?
                                                                <Grid.Row>
                                                                    <OrderConfirmationContent heading={orderConfirmationContent.createNewGiftingPayment.heading} body={orderConfirmationContent.createNewGiftingPayment.body}></OrderConfirmationContent>
                                                                </Grid.Row> :
                                                                <div></div>

                                }
                                {
                                    orderConfirmationData.LaybyDetails !== null && [6, 5].indexOf(orderConfirmationData.TransactionStatus) === -1 &&
                                    <Grid.Row>
                                        <OrderConfirmationColumn mobile={16} tablet={8} computer={4}>
                                            <H6>
                                                Next Payment Date
                                            </H6>
                                            <Paragraph1 color='#707070'>
                                                {moment(orderConfirmationData.LaybyDetails.NextPaymentDue).format('DD MMMM YYYY')}
                                            </Paragraph1>
                                        </OrderConfirmationColumn>
                                        <OrderConfirmationColumn mobile={16} tablet={8} computer={4}>
                                            <H6>
                                                Installment Amount
                                            </H6>
                                            <Paragraph1 color='#707070'>
                                                ${orderConfirmationData.LaybyDetails.SuggestedRegularAmount}
                                            </Paragraph1>
                                        </OrderConfirmationColumn>
                                        <OrderConfirmationColumn mobile={16} tablet={8} computer={4}>
                                            <H6>
                                                Remaining Installments
                                            </H6>
                                            <Paragraph1 color='#707070'>
                                                {orderConfirmationData.LaybyDetails.NumPeriods}
                                            </Paragraph1>
                                        </OrderConfirmationColumn>
                                        <OrderConfirmationColumn mobile={16} tablet={8} computer={4}>
                                            <H6>
                                                Estimated Completion
                                            </H6>
                                            <Paragraph1 color='#707070'>
                                                {moment(orderConfirmationData.LaybyDetails.EstimatedCompletionDate).format('DD MMMM YYYY')}
                                            </Paragraph1>
                                        </OrderConfirmationColumn>
                                    </Grid.Row>
                                }
                                <Grid.Row>
                                    {isDealerOrder && <OrderConfirmationContactInfoContainerMobile width={16}>
                                        <Paragraph1 color='black'>
                                            <strong>Place Another Order</strong><br />
                                            <Link to='/dealer-new-order/'>
                                                <KpButton id="place-another-order" buttonType={ButtonTypes.Primary} color={theme.brand.colors.blue}>Place Another Order</KpButton>
                                            </Link>
                                        </Paragraph1>
                                    </OrderConfirmationContactInfoContainerMobile>}
                                    <OrderConfirmationContactInfoContainer width={16}>
                                        <Paragraph1 color='black'>
                                            <strong>Tell us how we did</strong><br />
                                            <Link target='__blank' to='https://www.google.com/search?sxsrf=ALeKk00I8UAZIlM_FlJezX4E2ZaDP9UD-g%3A1597875656882&ei=yKU9X-O8NdGV4-EPn6-_6As&q=kiwiplates&oq=kiwiplates&gs_lcp=CgZwc3ktYWIQAzINCC4QxwEQrwEQJxCTAjICCAAyAggAMgIIADICCAAyAggAMgIIADICCAAyBwgAEBQQhwIyAggAOgQIABBHOggILhDHARCvAToKCC4QxwEQrwEQCjoICC4QxwEQowI6BAgAEApQ6i1YkjJgijNoAHABeACAAcYBiAH4BpIBAzAuNZgBAKABAaoBB2d3cy13aXrAAQE&sclient=psy-ab&ved=0ahUKEwjjwLHXpqjrAhXRyjgGHZ_XD70Q4dUDCAw&uact=5#lrd=0x6d0d48057eb92787:0xcb1617cfb7860625,3,,,'>
                                                <GoogleReviewButton id='google_review_button' buttonType='primary' color={theme.brand.colors.blue}>leave a review</GoogleReviewButton>
                                            </Link>
                                        </Paragraph1>

                                    </OrderConfirmationContactInfoContainer>
                                    <OrderConfirmationContactInfoContainer mobile={16} tablet={7} computer={7}>
                                        {isDealerOrder ? <Paragraph1 color='#707070'>
                                            Our Dealer team is here to help if you have any questions 
                                        </Paragraph1> 
                                        : <Paragraph1 color='#707070'>
                                            Our Customer service team is here to help if you have any questions
                                        </Paragraph1>}
                                        <OrderConfirmationLinkContainer>
                                            <a href="tel:0800650111">
                                                <OrderConfirmationLink>
                                                    0800 650 111
                                                </OrderConfirmationLink>
                                            </a>
                                        </OrderConfirmationLinkContainer>
                                        <OrderConfirmationLinkContainer>
                                            {isDealerOrder ? <a href="mailto:dealers@kiwiplates.nz">
                                                <OrderConfirmationLink>
                                                    dealers@kiwiplates.nz
                                                </OrderConfirmationLink>
                                            </a> : <a href="mailto:hello@kiwiplates.nz">
                                                <OrderConfirmationLink>
                                                    hello@kiwiplates.nz
                                                </OrderConfirmationLink>
                                            </a>}
                                        </OrderConfirmationLinkContainer>
                                        <OrderConfirmationLinkContainer>
                                            <OrderConfirmationLink>
                                                Mon-Fri 9am-5pm
                                            </OrderConfirmationLink>
                                        </OrderConfirmationLinkContainer>
                                    </OrderConfirmationContactInfoContainer>
                                </Grid.Row>
                                {
                                    orderConfirmationData.DeliveryType !== 1 && isMultiOrder === false && !isDealerOrder &&
                                    <Grid.Row>
                                        <OrderConfirmationDeliveryProgress isLayby={orderConfirmationData.LaybyDetails !== null} transactionStatus={orderConfirmationData.TransactionStatus}></OrderConfirmationDeliveryProgress>
                                    </Grid.Row>
                                }
                            </Grid>
                        }
                        {
                            orderConfirmationData === undefined &&
                            <Grid>
                                <Grid.Row>
                                    <OrderConfirmationContent heading="Thanks for your order!" body="<p>Your plates are now being reviewed to make sure they meet our sociable plates policy.  This can take up to 24 hours (or 1-2 days for plates ordered on the weekend)</p>"></OrderConfirmationContent>

                                </Grid.Row>
                                <Grid.Row>
                                    {isDealerOrder && <OrderConfirmationContactInfoContainerMobile width={16}>
                                        <Paragraph1 color='black'>
                                            <strong>Place Another Order</strong><br />
                                            <Link to='/dealer-new-order/'>
                                                <KpButton id="place-another-order" buttonType={ButtonTypes.Primary} color={theme.brand.colors.blue}>Place Another Order</KpButton>
                                            </Link>
                                        </Paragraph1>
                                    </OrderConfirmationContactInfoContainerMobile>}
                                    <OrderConfirmationContactInfoContainer width={16}>
                                        <Paragraph1 color='black'>
                                            <strong>Tell us how we did</strong><br />
                                            <Link target='__blank' to='https://www.google.com/search?sxsrf=ALeKk00I8UAZIlM_FlJezX4E2ZaDP9UD-g%3A1597875656882&ei=yKU9X-O8NdGV4-EPn6-_6As&q=kiwiplates&oq=kiwiplates&gs_lcp=CgZwc3ktYWIQAzINCC4QxwEQrwEQJxCTAjICCAAyAggAMgIIADICCAAyAggAMgIIADICCAAyBwgAEBQQhwIyAggAOgQIABBHOggILhDHARCvAToKCC4QxwEQrwEQCjoICC4QxwEQowI6BAgAEApQ6i1YkjJgijNoAHABeACAAcYBiAH4BpIBAzAuNZgBAKABAaoBB2d3cy13aXrAAQE&sclient=psy-ab&ved=0ahUKEwjjwLHXpqjrAhXRyjgGHZ_XD70Q4dUDCAw&uact=5#lrd=0x6d0d48057eb92787:0xcb1617cfb7860625,3,,,'>
                                                <GoogleReviewButton id='google_review_button' buttonType='primary' color={theme.brand.colors.blue}>leave a review</GoogleReviewButton>
                                            </Link>
                                        </Paragraph1>

                                    </OrderConfirmationContactInfoContainer>
                                    <OrderConfirmationContactInfoContainer mobile={16} tablet={7} computer={7}>
                                        {isDealerOrder ? <Paragraph1 color='#707070'>
                                            Our Dealer team is here to help if you have any questions 
                                        </Paragraph1> 
                                        : <Paragraph1 color='#707070'>
                                            Our Customer service team is here to help if you have any questions
                                        </Paragraph1>}
                                        <OrderConfirmationLinkContainer>
                                            <a href="tel:0800650111">
                                                <OrderConfirmationLink>
                                                    0800 650 111
                                                </OrderConfirmationLink>
                                            </a>
                                        </OrderConfirmationLinkContainer>
                                        <OrderConfirmationLinkContainer>
                                            {isDealerOrder ? <a href="mailto:dealers@kiwiplates.nz">
                                                <OrderConfirmationLink>
                                                    dealers@kiwiplates.nz
                                                </OrderConfirmationLink>
                                            </a> : <a href="mailto:hello@kiwiplates.nz">
                                                <OrderConfirmationLink>
                                                    hello@kiwiplates.nz
                                                </OrderConfirmationLink>
                                            </a>}
                                        </OrderConfirmationLinkContainer>
                                        <OrderConfirmationLinkContainer>
                                            <OrderConfirmationLink>
                                                Mon-Fri 9am-5pm
                                            </OrderConfirmationLink>
                                        </OrderConfirmationLinkContainer>
                                    </OrderConfirmationContactInfoContainer>
                                </Grid.Row>
                            </Grid>
                        }
                    </div>
                }
                {
                    !this.state.isLoading && !this.state.success &&
                    <div>
                        <H2>Payment Failed</H2>
                        <Paragraph1>
                            {this.state.error !== 'undefined' && <div>{this.state.error}</div>}
                        </Paragraph1>
                        <Paragraph1>
                            Your order has been saved. Please give us a call on <a href="tel:0800650111">0800 650 111</a> to complete your order.
                        </Paragraph1>
                    </div>
                }
                {
                    this.state.isLoading &&
                    <div>
                        <Grid>
                            <Grid.Column width={16}>
                                <H2>
                                    Loading Order
                                    <LoadingIcon name='circle notch' size='huge' loading />
                                </H2>
                            </Grid.Column>
                        </Grid>
                    </div>
                }
            </div>
        )
    }

}

const mapStateToProps = (state: Record<any>) => {
    const basket = currentBasketSelector(state).toJS();
    const checkout = currentCheckoutSelector(state).toJS();
    return {
        basket: basket,
        checkout: checkout
    };
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    clearBasketAndCheckout() {
        dispatch(clearBasketAndCheckout());
    },
    clearBasket(){
        dispatch(clearBasket())
    },
    updateDeliveryAddress(deliveryAddress: Address & {
        title: string
        firstName: string
        lastName: string
        sameAsOwnerAddress: boolean
        companyName: string
      }) {
        dispatch(updateDeliveryAddress(deliveryAddress));
    },
    updatePersonalDetails(personalDetails: Checkout) {
        dispatch(updatePersonalDetails(personalDetails))
    },
    updateIsAcceptStatement(isAcceptStatement: boolean) {
      dispatch(updateIsAcceptStatement(isAcceptStatement));
    }
})

const OrderConfirmation = connect(mapStateToProps, mapDispatchToProps)(OrderConfirmationContainer);

export default OrderConfirmation;

