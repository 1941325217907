import React from 'react'
import { Paragraph1 } from '@styles/Global.styles'; 
import { OrderConfirmationContentHeading } from './OrderConfirmation.styles';

interface OrderConfirmationContentProps {
    heading: string,
    body: string,
    isLayby?: boolean
}

class OrderConfirmationContent extends React.Component<OrderConfirmationContentProps>{

    render(){

        const { heading, body } = this.props;
        return ( 
            <div>
                <OrderConfirmationContentHeading>
                    {heading}
                </OrderConfirmationContentHeading>                
                <Paragraph1 dangerouslySetInnerHTML={{ __html: body }}></Paragraph1>
            </div>
        )
    }

}

export default OrderConfirmationContent