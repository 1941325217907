import React from 'react'
import { 
    OrderSummaryDeliveryProgressContainer, 
    OrderSummaryDeliveryProgressStep, 
    OrderSummaryDeliveryProgressConnector, 
    OrderSummaryDeliveryProgressRow, 
    OrderSummaryDeliveryProgressSegment 
} from './OrderConfirmation.styles';
import { Icon } from 'semantic-ui-react';
import { H6 } from '@styles/Global.styles';

interface OrderConfirmationDeliveryProgressProps {
    isLayby: boolean,
    transactionStatus: number
}

class OrderConfirmationDeliveryProgress extends React.Component<OrderConfirmationDeliveryProgressProps>{

    isStepComplete(){
        const status = this.props.transactionStatus;

        if([1,2,3,4,9,12].indexOf(status) !== -1) return 1
        if([8,13,16].indexOf(status) !== -1) return 2
        if([5,14].indexOf(status) !== -1) return 3
        if([6].indexOf(status) !== -1 || (this.props.isLayby && status === 5)) return 4
        return 0;
    }

    render(){

        const { isLayby, transactionStatus } = this.props;

        return ( 
            <OrderSummaryDeliveryProgressSegment basic>
                {
                    [11,10,7].indexOf(transactionStatus) === -1 &&
                    <OrderSummaryDeliveryProgressContainer>
                        <OrderSummaryDeliveryProgressRow>
                            <H6>Order<br/>Processing</H6>
                            <H6>Order<br/>Approved</H6>
                            { isLayby ? <H6>Layby In<br/>Progress</H6> : <H6>Plates Sent<br/>for Manufacture</H6> }
                            { isLayby ? <H6>Layby paid<br/>in Full</H6> : <H6>Plates<br/>Dispatched</H6> }
                        </OrderSummaryDeliveryProgressRow>
                        <OrderSummaryDeliveryProgressRow>
                            <OrderSummaryDeliveryProgressStep className='complete'>
                                <Icon name='check' size='big' />
                            </OrderSummaryDeliveryProgressStep>
                            <OrderSummaryDeliveryProgressConnector className={this.isStepComplete() > 1 ? 'complete' : ''}></OrderSummaryDeliveryProgressConnector>
                            <OrderSummaryDeliveryProgressStep className={this.isStepComplete() > 1 ? 'complete' : ''}>
                                {
                                    this.isStepComplete() > 1 &&
                                    <Icon name='check' size='big' />
                                }
                            </OrderSummaryDeliveryProgressStep>
                            <OrderSummaryDeliveryProgressConnector className={this.isStepComplete() > 2 ? 'complete' : ''}></OrderSummaryDeliveryProgressConnector>
                            <OrderSummaryDeliveryProgressStep className={this.isStepComplete() > 2 ? 'complete' : ''}>
                                {
                                    this.isStepComplete() > 2 &&
                                    <Icon name='check' size='big' />
                                }
                            </OrderSummaryDeliveryProgressStep>
                            <OrderSummaryDeliveryProgressConnector className={this.isStepComplete() > 3 ? 'complete' : ''}></OrderSummaryDeliveryProgressConnector>
                            <OrderSummaryDeliveryProgressStep className={this.isStepComplete() > 3 ? 'complete' : ''}>
                                {
                                    this.isStepComplete() > 3 &&
                                    <Icon name='check' size='big' />
                                }
                            </OrderSummaryDeliveryProgressStep>
                        </OrderSummaryDeliveryProgressRow>
                    </OrderSummaryDeliveryProgressContainer>
                }
                {
                    [11,10,7].indexOf(transactionStatus) !== -1 &&
                    <OrderSummaryDeliveryProgressContainer>
                        <OrderSummaryDeliveryProgressRow>
                            <H6>Order<br/>Processing</H6>
                            <H6>Order<br/>Cancelled</H6>
                        </OrderSummaryDeliveryProgressRow>
                        <OrderSummaryDeliveryProgressRow>
                            <OrderSummaryDeliveryProgressStep className='complete'>
                                <Icon name='check' size='big' />  
                            </OrderSummaryDeliveryProgressStep>
                            <OrderSummaryDeliveryProgressConnector className='complete'></OrderSummaryDeliveryProgressConnector>
                            <OrderSummaryDeliveryProgressStep className='cancelled'>
                            <Icon name='close' size='big' />    
                            </OrderSummaryDeliveryProgressStep>
                        </OrderSummaryDeliveryProgressRow>
                    </OrderSummaryDeliveryProgressContainer>
                }

            </OrderSummaryDeliveryProgressSegment>
        )
    }

}

export default OrderConfirmationDeliveryProgress