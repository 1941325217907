import { OrderConfirmationContentModel, OrderConfirmationContentModelKentico } from "@models/OrderConfirmation";

export const mapToOrderConfirmation = (data: OrderConfirmationContentModelKentico): OrderConfirmationContentModel => {
    return {
        createNewFullPaymentManufactureLater: {
            body: data.create_new___full_payment___manufacture_later.linked_items[0].elements.body.value,
            heading: data.create_new___full_payment___manufacture_later.linked_items[0].elements.heading.value
        },
        createNewFullPaymentManufactureNow: {
            body: data.create_new___full_payment.linked_items[0].elements.body.value,
            heading: data.create_new___full_payment.linked_items[0].elements.heading.value
        },
        createNewGiftingPayment: {
            body: data.create_new___gifting_payment.linked_items[0].elements.body.value,
            heading: data.create_new___gifting_payment.linked_items[0].elements.heading.value
        },
        createNewLaybyPayment: {
            body: data.create_new___layby_payment.linked_items[0].elements.body.value,
            heading: data.create_new___layby_payment.linked_items[0].elements.heading.value
        },
        redesignFullPaymentManufactureLater: {
            body: data.redesign___full_payment___manufacture_later.linked_items[0].elements.body.value,
            heading: data.redesign___full_payment___manufacture_later.linked_items[0].elements.heading.value
        },
        redesignFullPaymentManufactureNow: {
            body: data.redesign___full_payment___manufacture_now.linked_items[0].elements.body.value,
            heading: data.redesign___full_payment___manufacture_now.linked_items[0].elements.heading.value
        },
        multipleOrder:{
            body: data.multiple_order.linked_items[0].elements.body.value,
            heading: data.multiple_order.linked_items[0].elements.heading.value
        },
        dealerManufactureLater:{
            body: data.dealer___manufacture_later.linked_items[0].elements.body.value,
            heading: data.dealer___manufacture_later.linked_items[0].elements.heading.value
        },
        dealerManufactureNow:{
            body: data.dealer___manufacture_now.linked_items[0].elements.body.value,
            heading: data.dealer___manufacture_now.linked_items[0].elements.heading.value
        },
        dealerOnRequest:{
            body: data.dealer___on_request.linked_items[0].elements.body.value,
            heading: data.dealer___on_request.linked_items[0].elements.heading.value
        },
        dealerTopReviewStatement:{
            body: data.dealer___top_review_statement.linked_items[0].elements.body.value,
            heading: data.dealer___top_review_statement.linked_items[0].elements.heading.value,
        }
    }
}